<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="chart-bar"></b-icon>
          <span>
            {{ $ml.with(room.name).get('statistic_room') }}
          </span>
        </h4>
      </div>
    </div>
    <div class="columns is-centered is-variable is-4">
      <div class="column is-2">
        <div class="dash-plate">
          <div class="header is-primary">{{ $ml.get('dashboard_bets_count') }}</div>
          <div class="body">
            <div class="side-icon text-primary">
              <b-icon icon="star"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ new Intl.NumberFormat().format(room.bets_count) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate">
          <div class="header is-info">{{ $ml.get('dashboard_turnover') }}</div>
          <div class="body">
            <div class="side-icon has-text-info">
              <b-icon icon="reload"></b-icon>
            </div>
            <div class="side-info">
              <span><Money currency="EUR" :subunits=true v-bind:amount="room.turnover"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate">
          <div class="header is-success">{{ $ml.get('dashboard_profit') }}</div>
          <div class="body">
            <div class="side-icon has-text-success">
              <b-icon icon="currency-usd"></b-icon>
            </div>
            <div class="side-info">
              <span><Money :subunits=true currency="EUR" v-bind:amount="room.profit"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate">
          <div class="header is-warning">ROI</div>
          <div class="body">
            <div class="side-icon has-text-warning">
              <b-icon icon="finance"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ room.roi }} %</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-8">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="date" width="60" :label="$ml.get('statistic_date')" sortable v-slot="props">
            {{ props.row.date }}
          </b-table-column>
          <b-table-column field="bets" width="60" :label="$ml.get('statistic_bets_count')" sortable v-slot="props">
            {{ new Intl.NumberFormat().format(props.row.bets) }}
          </b-table-column>
          <b-table-column field="turnover" width="80" :label="$ml.get('statistic_turnover')" sortable v-slot="props">
            <Money currency="EUR" v-bind:amount="props.row.turnover"/>
          </b-table-column>
          <b-table-column field="profit" width="80" :label="$ml.get('statistic_profit')" sortable v-slot="props">
            <span :class="props.row.profit >= 0 ? 'text-success' : 'text-danger'">
              <Money currency="EUR" v-bind:amount="props.row.profit"/>
            </span>
          </b-table-column>
          <b-table-column field="roi" width="50" label="ROI" sortable v-slot="props">
            <span :class="props.row.roi >= 0 ? 'text-success' : 'text-danger'">
              {{ props.row.roi }} %
            </span>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Money from "@/components/Money";

export default {
  name: "Room",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: false,
      items: [],
      room: {
        turnover: 0,
        profit: 0,
        roi: 0,
        bets_count: 0,
      },
    }
  },
  components: {Money},
  computed: {},
  methods: {
    retrieve() {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `statistic/room?id=${this.room.id}`).then(resp => {
        this.room = resp.data.result.room
        this.items = resp.data.result.items
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.room.id = this.$route.query.id
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_statistic')
    }
  }
}
</script>

<style scoped>
</style>